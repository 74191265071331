import { Spacer } from "~/widgets/spacer";
import { CollectPhoneForm } from "./collect_phone_form";
import { createSignal, Match, Switch } from "solid-js";
import { CollectOtp } from "./collect_otp_form";
import { useNavigate } from "@solidjs/router";
import { VerifyPartnerOTPResponse } from "~/server/types/brand";
import PlutoAuth from "../pluto_auth";
import { useModal } from "~/components/modal";
import { OnboardingModal } from "./onboarding_modal";
import { Appbar } from "../app_bar";
import { CollectNameForm } from "./collect_name_form";
import { updateUserProfile } from "~/server/apis/client_apis";

export default function PlutoLoginComponent() {
  const [step, setStep] = createSignal<"phone" | "otp" | "name">("phone");
  const [otpToken, setOtpToken] = createSignal<string>("");
  const [phone, setPhone] = createSignal<string>("8953446887");
  const navigate = useNavigate();
  const plutoAuth = new PlutoAuth();

  return (
    <div class="dark flex w-full flex-col bg-plutoLoginBgMob bg-cover bg-no-repeat px-4">
      <div class="py-1.5">
        <Appbar />
      </div>
      <Spacer height={20} />
      <Switch>
        <Match when={step() === "phone"}>{buildCollectPhoneForm()}</Match>
        <Match when={step() === "otp"}>{buildCollectOtpForm()}</Match>
        <Match when={step() === "name"}>{buildCollectNameForm()}</Match>
      </Switch>
    </div>
  );

  function buildCollectPhoneForm() {
    return (
      <CollectPhoneForm
        onValidPhoneNumberSubmitted={(phoneNumber, otpToken) => {
          setOtpToken(otpToken);
          setPhone(phoneNumber);
          setStep("otp");
        }}
        submitPhoneNumber={async (phoneNumber) => {
          const response = await plutoAuth.sendOtp(phoneNumber);
          return {
            otpToken: response.otpToken,
          };
        }}
      />
    );
  }

  function buildCollectOtpForm() {
    return (
      <CollectOtp<VerifyPartnerOTPResponse>
        onVerificationSuccess={(response) => {
          plutoAuth.setSessionId(response.sessionId);
          if (response.isNewUser) {
            setStep("name");
          } else {
            navigate("/gifts/new");
          }
        }}
        otpToken={otpToken()}
        phoneNumber={phone()}
        resendOtp={async (number) => {
          const response = await plutoAuth.sendOtp(number);
          setOtpToken(response.otpToken);
          return {
            otpToken: response.otpToken,
          };
        }}
        submitOtp={async (props) => {
          return await plutoAuth.verifyOtp({
            phoneNumber: phone(),
            otp: props.otp,
            otpToken: props.otpToken,
          });
        }}
        onClickEditPhoneNumber={() => {
          setStep("phone");
        }}
      />
    );
  }

  function buildCollectNameForm() {
    const { openModal } = useModal()!;

    const onboardingModal = () => {
      openModal(
        () => <OnboardingModal />,
        "w-screen max-h-screen dark",
        "border-none dark:!bg-plutoLoginBgMob dark:!bg-cover dark:!bg-no-repeat"
      );
    };

    return (
      <CollectNameForm
        onSuccess={() => {
          onboardingModal();
        }}
        submitName={async (firstName, lastName) => {
          return await updateUserProfile({
            firstName,
            lastName,
            phoneNumber: phone(),
          });
        }}
      />
    );
  }
}
