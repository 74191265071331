import { useNavigate } from "@solidjs/router";
import { useModal } from "~/components/modal";
import { PhosphorIcon } from "~/widgets/icons";
import { Appbar } from "../app_bar";

export function OnboardingModal() {
  const { closeModal } = useModal()!;
  const navigate = useNavigate();

  return (
    <div class="relative h-screen w-full">
      <div class="px-4 py-1.5">
        <Appbar />
      </div>
      <div class="mt-8 flex flex-col gap-2 px-4 text-white">
        <p class="text-smallBold uppercase ">Boring but important stuff</p>
        <h1 class="mb-6 text-[28px] font-semibold">
          Before you start talking to Pluto, please note:
        </h1>
      </div>
      <div class="mx-4 flex flex-col gap-6 border-t-[0.5px] border-basePrimaryMedium pt-6 text-white">
        <div class="flex gap-2">
          <PhosphorIcon
            name="star-four"
            fontSize={20}
            size="fill"
            class={`h-5 w-5 text-baseTertiaryLight`}
          />
          <div class="flex flex-col items-baseline gap-1">
            <h3 class="text-bold">Pluto is only for gifting</h3>
            <p class="text-medium text-baseSecondaryLight">
              If Pluto detects you're off-topic, the conversation will end
              without a gift.
            </p>
          </div>
        </div>
        <div class="flex gap-2">
          <PhosphorIcon
            name="star-four"
            fontSize={20}
            size="fill"
            class={`h-5 w-5 text-baseTertiaryLight`}
          />
          <div class="flex flex-col items-baseline gap-1">
            <h3 class="text-bold">Pluto is still improving</h3>
            <p class="text-medium text-baseSecondaryLight">
              Everything is AI-driven and in beta—expect some surprises! Your
              feedback helps us get better.
            </p>
          </div>
        </div>
      </div>
      <div class="absolute bottom-5 mx-2 w-[95%]">
        <button
          onClick={() => {
            closeModal();
            navigate("/gifts/new");
          }}
          class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
        >
          <p class="text-buttonMedium font-bold text-textDark">
            Understood, let’s start
          </p>
        </button>
      </div>
    </div>
  );
}
